.headerWelcomeline {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1000;
    height: 38px;
    width: 100%;
    background: linear-gradient(92.86deg, #556EE6 0%, #6B21A8 100%);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    margin: 8px 0;
    border-radius: 8px;

    .marqueeContainer {
        display: flex;
        width: 100%;

        &.animated {
            animation: marquee 10s linear infinite;
        }

        &.paused {
            animation: none;
            transform: translateX(-100%);
        }

        &:hover {
            animation-play-state: paused;
        }

        .marquee {
            display: flex;
            width: 100%;

            p {
                color: #FFF;
                font-family: "Poppins", serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                white-space: nowrap;
                padding-right: 50px;

                a {
                    color: #7C92FF;
                    font-family: "Poppins", serif;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    text-decoration-line: underline;
                }
            }
        }

        @keyframes marquee {
            0% {
                transform: translateX(0);
            }

            100% {
                transform: translateX(-100%);
            }
        }
    }
}
