.SendInvitationEmailmain {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: var(--black-6);
  z-index: 9999999999;
  display: flex;
  align-items: center;
  justify-content: center;

  .sendInvitationEmailModalBox {
    border-radius: 16px;
    background: var(--white);
    width: 479px;

    .SendinvitationEmailModalHeding {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px;
      border-bottom: 1px solid #f1f1f5;

      h4 {
        color: var(--bookmark-heading-color);
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.1px;
      }

      .SendininvitationEmailheadingCLose {
        width: 24px;
        height: 24px;
        cursor: pointer;

        &:hover {
          background: var(--primary-50);
          border-radius: 4px;
        }
      }
    }

    .SendinvitationEmailModelbody {
      padding: 11px 10px 16px 10px;

      .SendinvitationEmailModeltitle {
        p {
          text-align: center;
          color: #556ee6;
          font-family: 'Poppins', serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          padding: 0 0 13px 0;
        }

        .SendInvitationEmailmodelSearchbar {
          position: relative;

          input {
            border: 1px solid #f3f3f3;
            border-radius: 12px;
            height: 40px;
            width: 100%;
            box-sizing: border-box;
            padding: 0 40px;
          }

          .SendInvitationEmailmodelSearcicon {
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }

      .SendinvitationEmailModelSelectedfriendnumbers {
        padding: 17px 0 13px 16px;

        p {
          color: #808191;
          font-family: 'Poppins', serif;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
        }
      }

      .SendinvitationEmailModelSelectfriend {
        display: flex;
        justify-content: end;
        align-items: center;
        gap: 12px;
        padding: 0 31px;

        p {
          color: #000;
          font-family: 'Poppins', serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
        }

        .SendinvitationEmailModelSelectallfriendcheckbox {
          width: 20px;
          height: 20px;
          border-radius: 6px;
          border: 2px solid #acb1c0;
          padding: 2px;
          cursor: pointer;
          transition: all 0.3s ease-in-out;

          &.SendinvitationEmailModelSelectallfriendcheckboxcheck {
            border-color: #556ee6;
            transition: all 0.3s ease-in-out;

            .SendinvitationEmailModelSelectallfriendcheckboxcheckedcircle {
              transition: all 0.3s ease-in-out;
              background-color: #556ee6;
            }
          }

          .SendinvitationEmailModelSelectallfriendcheckboxcheckedcircle {
            transition: all 0.3s ease-in-out;
            background-color: transparent;
            width: 100%;
            height: 100%;
            border-radius: 3px;
          }
        }
      }

      ::-webkit-scrollbar {
        width: 3px !important;
        display: block !important;
      }

      ::-webkit-scrollbar-thumb {
        background-color: #d9d9d9 !important;
      }

      .SendinvitationEmailModelSelectedfriendsmainbody {
        width: 100%;
        height: 296px;
        overflow-y: auto;
        margin: 18px 0 70px 0;
        padding: 8px 7px 0 0;

        .SendinvitationEmailModelSelectedfriendsboxm {
          border-radius: 10px;
          background: transparent;
          margin: 0 6px 0 0;
          padding: 16px 21px 16px 14px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          transition: 0.3s ease-in-out;

          &:hover {
            transition: 0.3s ease-in-out;
            background: #556ee621;
          }

          span {
            color: #000;
            font-family: 'Poppins', serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
          }

          .SendinvitationEmailModelSelectallfriendcheckbox {
            width: 20px;
            height: 20px;
            border-radius: 6px;
            border: 2px solid #acb1c0;
            padding: 2px;
            cursor: pointer;
            transition: all 0.3s ease-in-out;

            &.SendinvitationEmailModelSelectallfriendcheckboxcheck {
              border-color: #556ee6;
              transition: all 0.3s ease-in-out;

              .SendinvitationEmailModelSelectallfriendcheckboxcheckedcircle {
                transition: all 0.3s ease-in-out;
                background-color: #556ee6;
              }
            }

            .SendinvitationEmailModelSelectallfriendcheckboxcheckedcircle {
              transition: all 0.3s ease-in-out;
              background-color: transparent;
              width: 100%;
              height: 100%;
              border-radius: 3px;
            }
          }
        }
      }

      .SendininvitationEmailLastbuttonsmain {
        padding: 0 18px;
        display: flex;
        justify-content: end;
        align-items: center;
        gap: 42px;

        button:last-child {
          color: #fff;
          background-color: #556ee6;
          border-radius: 8px;
          border-color: #556ee6;
          transition: all 0.3s ease-in-out;

          &:hover {
            transition: all 0.3s ease-in-out;
            color: #000;
            background-color: transparent;
          }

          &:disabled {
            color: var(--white);
            background: var(--primary-500);
            border-color: var(--primary-500);
            cursor: not-allowed;
            opacity: 0.4;
          }
        }

        button:first-child {
          color: #000;
        }

        button {
          text-align: center;
          font-family: 'Poppins', serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          padding: 6px 11px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid transparent;
          box-sizing: border-box;
        }
      }
    }
  }
}
