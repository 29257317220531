.sendInvitationModalWrapper {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: var(--black-6);
    z-index: 9999999999;
    display: flex;
    align-items: center;
    justify-content: center;

    .sendInvitationModalBox {
        border-radius: 16px;
        background: var(--white);
        width: 479px;

        .invitationModalHeding {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 16px;
            border-bottom: 1px solid #F1F1F5;

            h4 {
                color: var(--bookmark-heading-color);
                font-size: 18px;
                font-weight: 600;
                line-height: 24px;
                letter-spacing: 0.1px;
            }

            .headingCLose {
                width: 24px;
                height: 24px;
                cursor: pointer;

                &:hover {
                    background: var(--primary-50);
                    border-radius: 4px;
                }
            }
        }

        .sendInvitationModalBody {
            padding: 16px;

            p {
                color: var(--black-2);
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
            }

            .profileLinkInput {
                padding: 16px 0 0 0;

                label {
                    padding: 0 0 0 4px;
                    display: block;
                    color: var(--secondary-500);
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 18px;
                }

                .profileInput {
                    width: 100%;
                    height: 44px;
                    position: relative;

                    input {
                        width: 100%;
                        height: 100%;
                        padding: 0 40px 0 10px;
                        border-radius: 10px;
                        border: 1px solid var(--secondary-50);
                    }

                    .copyIcon {
                        position: absolute;
                        top: 50%;
                        right: 10px;
                        transform: translateY(-50%);
                        width: 24px;
                        height: 24px;
                        cursor: pointer;

                        &:hover {
                            svg {
                                path {
                                    stroke: var(--primary-500);
                                }
                            }
                        }
                    }

                }
            }

            .socialShareOptionDetails {
                padding: 24px 0 0 0;

                h6 {
                    color: var(--black-2);
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 21px;
                }

                .socialShareOption {
                    padding: 16px 0 0 0;
                    display: flex;
                    align-items: center;
                    gap: 16px;
                    flex-wrap: wrap;

                    .optionBox {
                        width: 48px;
                        height: 48px;
                        min-width: 48px;
                        max-width: 48px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 10px;
                        // background: var(--primary-50);
                        cursor: pointer;
                        transition: 0.4s ease-in-out;

                        &:hover {
                            transition: 0.4s ease-in-out;
                            background: var(--light-grey-3);
                        }
                    }
                }
            }

            .inviteFriendAlignment {
                padding: 32px 0 0 0;

                .inviteFriendLabel {
                    label {
                        color: var(--black-2);
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 21px;
                    }
                }

                .inviteFriendInput {
                    padding: 16px 0 0 0;

                    .inviteFriendGrid {
                        display: grid;
                        grid-template-columns: 1fr 40px;
                        gap: 4px;

                        .inviteFriendEmailInput {
                            width: 100%;
                            height: 40px;

                            input {
                                width: 100%;
                                height: 100%;
                                border-radius: 10px;
                                border: 1px solid var(--primary-50);
                                padding: 0 16px;
                                font-size: 14px;
                                font-weight: 400;
                                line-height: 21px;
                                color: var(--black-2);

                                &::placeholder {
                                    color: var(--secondary-500);
                                }
                            }
                        }

                        .inviteButton {
                            width: 100%;
                            height: 40px;

                            button {
                                width: 100%;
                                height: 100%;
                                border-radius: 10px;
                                background: var(--primary-50);
                                cursor: pointer;
                                border: none;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                        }
                    }
                }
            }

            .inviteFriendSendButton {
                padding: 32px 0 0 0;
                text-align: right;

                button {
                    width: 94px;
                    height: 32px;
                    border-radius: 8px;
                    background: var(--primary-500);
                    border: none;
                    cursor: pointer;
                    color: var(--white);
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 21px;
                    opacity: 0.4;
                }
            }
        }
    }
}